import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Root } from "../../routes/Root";
import { ErrorPage } from "../../ErrorPage";
import { SignUp } from "../../routes/SignUp";
import { AddVehicle } from "../../routes/AddVehicle";
import { Helmet } from "react-helmet-async";
import { LogIn } from "../../routes/LogIn";
import { UnauthenticatedLayout } from "../../layouts/UnauthenticatedLayout";
import { AuthenticatedLayout } from "../../layouts/AuthenticatedLayout";
import { AddLocation } from "../../routes/AddLocation";
import { Dashboard } from "../../routes/Dashboard";
import { ResetPassword } from "../../routes/ResetPassword";
import { AccountCreated } from "../../routes/AccountCreated";
import { VehicleAdded } from "../../routes/VehicleAdded";
import { LocationInstructions } from "../../routes/LocationInstructions";
import { LocationAdded } from "../../routes/LocationAdded";
import { Rewards } from "../../routes/Rewards";
import { Account } from "../../routes/Account";
import { EditLocation } from "../../routes/EditLocation";
import { getBrandConfig } from "../../brand-config";
import { BrandContext } from "../../context/brandContext";
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import getEnv from "../../env";
import { Provider } from 'react-redux';

import { AuthProvider } from "../../hooks/useAuth/useAuth";
import { AdminAuthProvider } from "../../admin/hooks/useAdminAuth";
import store from '../../store/rootState';
import AdminRoot from '../../admin/routes/AdminRoot';
import { Welcome } from '../../routes/Welcome';
import { Redirect } from '../../routes/Redirect';
import TermsAndConditions from '../../routes/TermsAndConditions';
import Landing from '../../routes/Landing';

const Auth0SecuredSection = lazy(() => import("../../admin/components/Auth0Security"));
const AdminLogin = lazy(() => import("../../admin/routes/AdminLogin"));
const AdminDashboard = lazy(() => import("../../admin/routes/AdminDashboard"));
const AdminLayout = lazy(() => import("../../admin/components/AdminLayout"));
//const AdminRoot = lazy(() => import("../../admin/routes/AdminRoot"));

const AppHelmet = () => {
  const config = getBrandConfig();
  return (
    <Helmet>
      <title>{config.websiteTitle}</title>
      <meta name="description" content={config.websiteMetaDescription} />
    </Helmet>
  );
};

const initAzureAppInsights = () => {
  var env = getEnv();
  if (env.INSIGHTS_CONNECTION_STRING) {
    var reactPlugin = new ReactPlugin();
    var appInsights = new ApplicationInsights({
      config: {
        connectionString: env.INSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: ['auth0.com'], // this prevents  app insights headers from being sent to auth0 that cause CORS errors
      }
    });
    appInsights.loadAppInsights();
    return reactPlugin;
  }
};

export const App = () => {
  // initTracking(); // wait to initialize until we have an email address

  const reactPlugin = initAzureAppInsights();

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <AuthProvider>
          <Root />
        </AuthProvider>),
      errorElement: <ErrorPage />,

      children: [
        {
          path: "/",
          element: (
            <UnauthenticatedLayout hasHeader={false}>
              <Landing />
            </UnauthenticatedLayout>
          ),
        },
        {
          path: "/signup",
          element: (
            <UnauthenticatedLayout>
              <SignUp />
            </UnauthenticatedLayout>
          ),
        },
        {
          path: "/login",
          element: (
            <UnauthenticatedLayout>
              <LogIn />
            </UnauthenticatedLayout>
          ),
        },
        {
          path: "/termsandconditions",
          element: (
            <UnauthenticatedLayout>
              <TermsAndConditions />
            </UnauthenticatedLayout>
          ),
        },
        {
          path: "/forgotpassword",
          element: (
            <UnauthenticatedLayout>
              <ResetPassword />
            </UnauthenticatedLayout>
          ),
        },
        {
          path: "/accountcreated",
          element: (
            <AuthenticatedLayout>
              <AccountCreated />
            </AuthenticatedLayout>
          ),
        },
        {
          path: "/addvehicle",
          element: (
            <AuthenticatedLayout>
              <AddVehicle />
            </AuthenticatedLayout>
          ),
        },
        {
          path: "/redirect",
          element: (
            <AuthenticatedLayout>
              <Redirect />
            </AuthenticatedLayout>
          ),
        },
        {
          path: "/vehicleadded",
          element: (
            <AuthenticatedLayout>
              <VehicleAdded />
            </AuthenticatedLayout>
          ),
        },
        {
          path: "/locationinstructions",
          element: (
            <AuthenticatedLayout>
              <LocationInstructions />
            </AuthenticatedLayout>
          ),
        },
        {
          path: "/addlocation",
          element: (
            <AuthenticatedLayout>
              <AddLocation />
            </AuthenticatedLayout>
          ),
        },
        {
          path: "/locationadded",
          element: (
            <AuthenticatedLayout>
              <LocationAdded />
            </AuthenticatedLayout>
          ),
        },
        {
          path: "/welcome",
          element: (
            <AuthenticatedLayout>
              <Welcome />
            </AuthenticatedLayout>
          ),
        },
        {
          path: "/dashboard",
          element: (
            <AuthenticatedLayout>
              <Dashboard />
            </AuthenticatedLayout>
          ),
        },
        {
          path: "/rewards",
          element: (
            <AuthenticatedLayout>
              <Rewards />
            </AuthenticatedLayout>
          ),
        },
        {
          path: "/account",
          element: (
            <AuthenticatedLayout>
              <Account />
            </AuthenticatedLayout>
          )
        },
        {
          path: "/editlocation",
          element: (
            <AuthenticatedLayout>
              <EditLocation />
            </AuthenticatedLayout>
          )
        },
        {
          path: "/account/termsandconditions",
          element: (
            <AuthenticatedLayout>
              <TermsAndConditions />
            </AuthenticatedLayout>
          )
        }
      ],
    },
    {
      path: "/admin",
      element: (
        <Suspense fallback={<div></div>}>
          <Auth0SecuredSection>
            <AdminAuthProvider>
              <Root wide={true} />
            </AdminAuthProvider>
          </Auth0SecuredSection>
        </Suspense>
      ),
      errorElement: <ErrorPage />,

      children: [
        {
          path: "/admin",
          element: (
            <AdminRoot />
          )
        },
        {
          path: "/admin/login",
          element: (
            <AdminLayout secure={false}>
              <AdminLogin />
            </AdminLayout>
          )
        },
        {
          path: "/admin/dashboard",
          element: (
            <AdminLayout>
              <AdminDashboard />
            </AdminLayout>
          )
        }
      ],
    },
  ]);

  return (
    reactPlugin ?
      <AppInsightsErrorBoundary onError={() => <h1>Sorry, there was a website error.</h1>} appInsights={reactPlugin} >
        <AppInsightsContext.Provider value={reactPlugin}>
          <BrandContext.Provider value={getBrandConfig()}>
            <Provider store={store}>
              <AppHelmet />
              <RouterProvider router={router} />
            </Provider>
          </BrandContext.Provider>
        </AppInsightsContext.Provider>
      </AppInsightsErrorBoundary >
      :
      <BrandContext.Provider value={getBrandConfig()}>
        <Provider store={store}>
          <AppHelmet />
          <RouterProvider router={router} />
        </Provider>
      </BrandContext.Provider>
  );
};
