import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { getUserInfo, userLogout } from "../../apis/energyNetApi";
import { trackUser } from "../../apis/tracking";
import useLocalStorageState from 'use-local-storage-state'
import { setupZenDesk } from "../../apis/zendesk";
import { useDispatch } from "react-redux";
import { resetState } from "../../store/actions";
import { updateUserInfo, useUserInfo } from "../../store/userInfoSlice";
import { useImpersonationToken } from "../../helpers/impersonation";

type AuthContextType = {
  user: string | null;
  email: string | null;
  testUser: boolean;
  login: (token: string, email: string) => void;
  logout: () => void;
  loading: boolean;
  hasAccount: true | null;
  isImpersonating: boolean;
};

const AuthContext = createContext<AuthContextType>({
  user: null,
  email: null,
  testUser: false,
  login: () => { },
  logout: () => { },
  loading: true,
  hasAccount: null,
  isImpersonating: false,
});

export const tokenKey = "user";
export const hasAccountKey = "hasAccount";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [userLocalStorage, setUser] = useLocalStorageState<string | null>(tokenKey, { defaultValue: null });
  const [hasAccount, setHasAccount] = useLocalStorageState<true | null>(hasAccountKey, { defaultValue: null });
  const userQueryString = new URLSearchParams(window.location.search).get("user");
  const [hasTrackedUser, setHasTrackedUser] = useState<boolean | null>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const userInfo = useUserInfo();

  const [impersonationToken, , removeImpersonationToken] = useImpersonationToken();
  const isImpersonating = !!impersonationToken !== null;

  const user = impersonationToken || userQueryString || userLocalStorage; // query takes precedence in case local has old token

  useEffect(() => {
    if (!user) {
      return;
    }

    getUserInfo(user)
      .then((response) => {
        if (response.data) {
          dispatch(updateUserInfo(response.data));
          setupZenDesk(response.data.Email, `${response.data.FirstName} ${response.data.LastName}`);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(
          `error calling '${getUserInfo}': ${error}`
        );
      });
  }, [user, dispatch]);

  const login = useCallback((token: string, email: string) => {
    setUser(token);
    setHasAccount(true);
    trackUser(email);
    setHasTrackedUser(true);
  }, [setUser, setHasTrackedUser, setHasAccount]);

  const logout = useCallback(() => {
    // clear all loaded redux data (cars, etc) in memory when the user logs out
    dispatch(resetState());

    const lastToken = user;
    if (lastToken === null) {
      return;
    }
    if (impersonationToken) {
      removeImpersonationToken();
      return;
    }
    setUser(null);
    setHasAccount(true);
    setHasTrackedUser(false);
    userLogout(lastToken)
      .then(() => { })
      .catch((error) => console.error(error));
  }, [dispatch, user, setUser, setHasTrackedUser, setHasAccount, impersonationToken, removeImpersonationToken]);

  const email = userInfo?.Email ?? null;
  const testUser = userInfo?.IsTestUser ?? false;
  const value: AuthContextType = { user, email, testUser, login, logout, loading, hasAccount, isImpersonating };

  // this handles the case where the user is already signed in
  if (!hasTrackedUser && email !== null) {
    trackUser(email);
    setHasTrackedUser(true);
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

