import * as querystring from 'querystring';

interface LoginInfo {
  CodeVerifier: string;
  State: string;
}


// Function to compute SHA-256 hash
async function computeSHA256HashInBytes(text: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(text);

  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // Convert the ArrayBuffer to a Uint8Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray;
}

function randomString(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

function base64UrlEncode(bytes: number[]) {
  const base64 = btoa(String.fromCharCode.apply(null, bytes));
  const encoded = base64
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  return encoded;
}

const getLoginUrlForBrowser = async (state: string) => {

  const loginInfo: LoginInfo = {
    CodeVerifier: randomString(86),
    State: state,
  };

  const codeChallenge = await computeSHA256HashInBytes(loginInfo.CodeVerifier);
  const encodedCodeChallenge = base64UrlEncode(codeChallenge);

  const clientId = 'dd3fd633090b-4808-be22-04ed49ce7f54';
  const clientBaseAddress = 'https://auth.tesla.com/';
  const redirectUri = `${window.location.protocol}//${window.location.host}/redirect`;
  const scopes = 'openid offline_access user_data vehicle_device_data vehicle_charging_cmds energy_device_data energy_cmds';

  const uriBuilder = new URL(clientBaseAddress + 'oauth2/v3/authorize');
  uriBuilder.port = '';

  const queryParams = {
    client_id: clientId,
    code_challenge: encodedCodeChallenge,
    code_challenge_method: 'S256',
    redirect_uri: redirectUri,
    response_type: 'code',
    scope: scopes,
    state: loginInfo.State,
    nonce: randomString(10),
    locale: navigator.language || "en-US",
  };

  uriBuilder.search = querystring.stringify(queryParams);

  return {
    url: uriBuilder.toString(),
    codeVerifier: loginInfo.CodeVerifier,
  };
}


export { getLoginUrlForBrowser };