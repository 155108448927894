import React, { ChangeEvent, useState } from "react";
import {
  AddLocationForm,
  AddLocationInputs,
} from "../components/AddLocationForm/AddLocationForm";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../hooks/useAuth/useAuth";
import { addChargingLocation, changeNotificationPhoneNumber, getChargingLocations } from "../apis/energyNetApi";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import { AddChargingLocationDTO, ChargingLocationDTO, ChargingLocationTypeEnum } from "../apis/energyNetTypes";
import { trackError } from "../apis/tracking";
import { Spinner } from "../components/Shared/Spinner";
import { BasicPageHeader } from "../components/Shared/BasicPageHeader";
import { getBrandConfig } from "../brand-config";
import { useChargingLocation, updateChargingLocation } from "../store/chargingLocationSlice";
import { updateUserInfo, useUserInfo } from "../store/userInfoSlice";
import { useTrackEvent } from "../hooks/useTrackEvent/useTrackEvent";
import { getGoogleLatLng } from "../hooks/useAddressAutoCompleteWidget/useAddressAutoCompleteWidget";

// This page is very similar to AddLocation but it updates the existing location
export const EditLocation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = getBrandConfig();

  const { user } = useAuth();
  const location = useChargingLocation();
  const userInfo = useUserInfo();
  const trackEvent = useTrackEvent();
  const [edited, setEdited] = useState(false);

  const [formInputs, setFormInputs] = useState<AddLocationInputs>({
    addressLineOne: "",
    city: "",
    state: "",
    postalCode: "",
    country: config.defaultCountry,
    utilityAccountId: "",
    phoneNumber: userInfo?.PhoneNumber || "",
    smsConsent: userInfo?.EnableSMSNotifications || false,
    latitude: location?.Latitude,
    longitude: location?.Longitude
  });

  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (!user || !userInfo) {
      return;
    }

    if (!location) {
      getChargingLocations(user)
        .then((response) => {
          if (!response.data?.length) {
            // no location set yet
            setFormInputs({
              addressLineOne: "",
              city: "",
              state: "",
              postalCode: "",
              country: config.defaultCountry,
              utilityAccountId: "",
              phoneNumber: userInfo?.PhoneNumber,
              smsConsent: userInfo?.EnableSMSNotifications || false,
            });
            trackError("EditLocation: No charging location found");
          } else {
            const address: ChargingLocationDTO = response.data[0];
            dispatch(updateChargingLocation(address));
            // loaded from api
            setFormInputs({
              addressLineOne: address.Address,
              city: address.City,
              state: address.State,
              postalCode: address.PostalCode,
              country: address.Country,
              utilityAccountId: address.UtilityAccountId,
              phoneNumber: userInfo?.PhoneNumber, // preparePhoneForDisplay(userInfo?.PhoneNumber || ""),
              smsConsent: userInfo?.EnableSMSNotifications || false,
              longitude: address.Longitude,
              latitude: address.Latitude
            });
          }
        })
        .catch((error) => {
          trackError("EditLocation: Error loading", error);
        });
    } else {
      // we've already got the location in redux
      setFormInputs({
        addressLineOne: location.Address,
        city: location.City,
        state: location.State,
        postalCode: location.PostalCode,
        country: location.Country,
        utilityAccountId: location.UtilityAccountId,
        phoneNumber: userInfo?.PhoneNumber, // preparePhoneForDisplay(userInfo?.PhoneNumber || ""),
        smsConsent: userInfo?.EnableSMSNotifications || false,
        longitude: location.Longitude,
        latitude: location.Latitude
      });
    }
  }, [user, dispatch, location, userInfo, config.defaultCountry]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    if ((event.target as HTMLInputElement).setCustomValidity) {
      // clear the validation message (checkbox doesn't have this)
      (event.target as HTMLInputElement).setCustomValidity("");
    }

    setFormInputs({
      ...formInputs,
      [event.target.name]: event.target.value,
    });

    if (event.target.name !== "phoneNumber" && event.target.name !== "smsConsent" && event.target.name !== "utilityAccountId") {
      // address edited - lat lon could be wrong now
      setEdited(true);
    }
  };

  const handleAddressSelected = (address: AddLocationInputs) => {
    setFormInputs((prevState) => {
      return {
        ...address,
        phoneNumber: prevState.phoneNumber,
        smsConsent: prevState.smsConsent,
        utilityAccountId: prevState.utilityAccountId
      }
    });

    // lat lon should be accurate now
    setEdited(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setBusy(true);

    if (edited) {
      // if they edited the address manually, we need to resolve the lat lon in case it's wrong or empty
      const geo = await getGoogleLatLng(`${formInputs.addressLineOne}, ${formInputs.city}, ${formInputs.state}, ${formInputs.country}, ${formInputs.postalCode}`);

      if (geo) {
        formInputs.latitude = geo.latitude;
        formInputs.longitude = geo.longitude
      }
      else {
        formInputs.latitude = undefined;
        formInputs.longitude = undefined;
      }
    }

    const guidId = location?.GuidId;
    if (user && guidId) {
      const chargingLocation: AddChargingLocationDTO = {
        guidId: guidId,
        name: "Home",
        chargingLocationType: ChargingLocationTypeEnum.Home,
        utilityName: config.utilityName,
        distributionUtilityName: config.distributionUtilityName,
        utilityRateScheduleName: config.rateSchedule,
        address: formInputs.addressLineOne?.trim(),
        city: formInputs.city?.trim(),
        state: formInputs.state?.trim(),
        country: formInputs.country?.trim(),
        postalCode: formInputs.postalCode?.trim(),
        notifyWhenDelayingCharging: true,
        notifyWhenNotPluggedIn: true,
        utilityAccountId: formInputs.utilityAccountId?.trim(), // todo: remove internal spaces.
        latitude: formInputs.latitude,
        longitude: formInputs.longitude
      };

      await addChargingLocation(user, chargingLocation);

      trackEvent("EditChargingLocation", chargingLocation);

      // update the address in the store
      // make a copy of location object so we don't mutate the original
      const updatedLocation = { ...location };

      updatedLocation.Address = formInputs.addressLineOne;
      updatedLocation.City = formInputs.city;
      updatedLocation.State = formInputs.state;
      updatedLocation.Country = formInputs.country;
      updatedLocation.PostalCode = formInputs.postalCode;
      updatedLocation.UtilityAccountId = formInputs.utilityAccountId;
      updatedLocation.Latitude = formInputs.latitude;
      updatedLocation.Longitude = formInputs.longitude;

      if (formInputs.phoneNumber !== userInfo?.PhoneNumber || formInputs.smsConsent !== userInfo?.EnableSMSNotifications) {
        try {
          const changePhoneResponse = await changeNotificationPhoneNumber(user, formInputs.phoneNumber, formInputs.smsConsent);

          // change phone number returns the updated userInfo
          dispatch(updateUserInfo(changePhoneResponse.data));
        }
        catch (ex) {
          trackError("EditLocation: Error updating phone number", ex);
        }
      }

      // don't update this before phone number so old phone number doesn't display during the post
      dispatch(updateChargingLocation(updatedLocation));

      navigate("/account");
      setBusy(false);
    } else {
      setBusy(false);
    }
  };

  const handleCancel = () => {
    navigate("/account");
  };

  return (
    <ResponsiveLayout>
      <>
        <BasicPageHeader title={config.addLocationHeader} />
        <div className={"form-contents-container"}>
          {location ?
            <AddLocationForm
              inputs={formInputs}
              onChange={handleChange}
              onSubmit={handleSubmit}
              onAddressSelected={handleAddressSelected}
              busy={busy}
              editExistingLocation={true}
              onCancel={handleCancel}
            /> :
            <div className="h-[300px]">
              <Spinner />
            </div>}
        </div>
      </>
    </ResponsiveLayout>
  );
};
