// these types are taken directly from the EnergyNet API C# code
export type Distance = {
  Meters: string;
};

export type UserInfoDTO = {
  Email: string;
  FirstName: string;
  LastName: string;
  Program: string;
  UseMetric: boolean;
  IsTestUser: boolean;
  IsDemoCarAdded: boolean;
  DeviceGuid: string;
  DefaultUtilityAccountNumber: string;
  DefaultUtilityId: string;
  DefaultServiceAgreementId: string;
  GUID: string;
  IsDeleted: boolean;
  CreatedTimestamp: string;
  ChargeLocationCount: number;
  HasResidentialChargingLocation: boolean;
  PhoneNumber: string;
  EnableSMSNotifications: boolean;
}

export type DeviceDTO = {
  Id: string;
  GUID: string;
  FriendlyName: string;
  MaximumRange?: Distance;
  CarType: string;
  Make: string;
  ModelName: string;
  ModelYear?: number;
  IsSmartChargingDisabled: boolean;
  IsCarPollingDisabled: boolean;
  UniqueId: string;
  HashedUniqueID: string;
  AccountGuid: string;
  UserGuid: string;
  SmartCarID: string;
  InternalHandlerType: string;
  CanDeviceStartAndStopCharging: boolean;
  LastChargeSessionDate?: string;
  Latitude: number;
  Longitude: number;
  ActualStateOfChargePercent: number;
  LastSeenTime?: string;
  Status: string;
  StatusDetail: string;
  CreatedTimeUTC?: string;
};

export enum ChargingChoice {
  Default = 0,
  ChargeImmediately = 1,
  TimeOfUse = 2,
  DoNothing = 3,
  CarbonDioxideSavings = 4,
  Solar = 5,
  Price = 6,
  //FleetScheduled = 7,
  //DynamicPricing = 8,
}

export enum SolarUsageGoal {
  Unspecified = 0,
  DriveOnSunlight = 1,
  SaveMoney = 2,
  Balanced = 3,  // Not sure what to do here...
}

export type ChargingLocationDTO = {
  Name: string;
  Latitude?: number;
  Longitude?: number;
  AltitudeInMeters: number;
  UtilityName: string;
  UtilityRateScheduleName: string;
  DistributionUtilityName: string;
  DistributionUtilityRateScheduleName: string;
  ChargingLocationType: string;
  ChargingChoice: ChargingChoice;
  NotifyWhenDelayingCharging: boolean;
  NotifyWhenNotPluggedIn: boolean;
  Address: string;
  City: string;
  State: string;
  County: string;
  Country: string;
  PostalCode: string;
  HasSolar: boolean;
  HasManagedCharging: boolean;
  SolarArrayPowerWatts: number;
  SolarUsageGoal: SolarUsageGoal;
  ProgramCode: string;
  GuidId: string;
  UtilityAccountId: string;
  ServiceAgreementId: string;
  MeterId: string;
  OffWkdyPriceReq: number | null;
  OffWkndPriceReq: number | null;
  MidWkdyPriceReq: number | null;
  MidWkndPriceReq: number | null;
  HighWkdyPriceReq: number | null;
  HighWkndPriceReq: number | null;
  UserGuid: string;
};

export enum ChargingLocationTypeEnum {
  Unknown = 0,
  Home = 1,
  Work = 2,
  School = 3,
  VacationHome = 4,
};
// update type has fewer fields
export type AddChargingLocationDTO = {
  guidId?: string;
  name: string;
  chargingLocationType: ChargingLocationTypeEnum;
  utilityName: string;
  distributionUtilityName: string;
  utilityRateScheduleName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  notifyWhenDelayingCharging: boolean;
  notifyWhenNotPluggedIn: boolean;
  utilityAccountId?: string;
  latitude?: number;
  longitude?: number;
};

export type DevicePreferencesDTO = {
  DeviceGUID: string;
  DeviceId?: string;
  DisableSmartCharging: boolean;
  DisableCarPolling: boolean;
};

export type DisableSmartChargingDTO = {
  DeviceGuid: string;
  TotalHours?: number;
  MinutesLeft?: number;
};

export enum ChargingState {
  Complete = 0,
  Charging = 1,
  Disconnected = 2,
  Pending = 3,
  NotCharging = 4,
  Starting = 5,
  Stopped = 6,
  Driving = 7,
  NoPower = 8,
  FailedToStartCharging = 9,
  FailedToStopCharging = 10,
  Asleep = 11,
  PollingError = 0x3F,
  NotAvailable = 0x40,
  DeviceOff = 65,
  Unknown = 66,
  RemoteAccessDisabled = 67
};

export type DeviceStatusDTO = {
  DeviceGUID: string;
  BatteryRange?: Distance;
  StateOfChargePercent: number;
  DesiredStateOfCharge?: number;
  ChargingState: ChargingState;
  IsPluggedIn: boolean;
  IsDeviceConnected: boolean;
  LastSeenTime?: string;
};

export type CarStateDTO = {
  Time: string;
  State: ChargingState;
  ChargeEnergyAdded?: Distance;
  ChargingStateName: string;
  BatteryEnergyLevel?: Distance;
  MustWakeUpOnNextPoll: boolean;
  NumSamplesInAveragePower: number;
  LastSeenTime?: string;
  DontPollUntil: string;
  ChangedStateSuccessfully: boolean;
  Location?: ChargingLocationDTO;
  KeepChargingUntilComplete: boolean;
  IsAtKnownChargingLocation: boolean;
  ActualStateOfChargePercent: number;
  DesiredStateOfChargePercent?: number;
  LastKnownStateOfChargePercent: number;
  LastKnownChargingState: ChargingState;
  FirstTimeInCurrentState?: string;
  PreviousStateAtStartOfPoll: ChargingState;
  FirstNoticedNotPluggedIn?: string;
  LastNotificationAboutNotPluggedIn?: string;
  LastNotificationAboutDelayedCharging?: string;
};

export type UnsupportedEVDTO = {
  email: string;
  make: string;
  model?: string;
  year?: string;
}

export type DeviceErrorDTO = {
  HasError: boolean;
  StatusMessages: StatusMessage[];
}

export type StatusMessage = {
  Status: string;
  SeviceGUID: string;
  AccountGUID: string;
  ConnectionID: string;
  CarPollingSourceType: string;
  StatusDetail: string;
  EmailAddress: string;
  ManufacturingType: string;
  lastMessageDateTime: Date;
};

export type DistanceDTO = {
  Meters: number;
};

export type ChargingSessionDTO = {
  Id: string;
  ChargingStarted: Date;
  AddedDistance?: DistanceDTO;
  GasCost: number;
  EnergyCost: number;
  IsSessionManaged: boolean;
  LocationType: string;
  IsKnown: boolean;
  KwhUsage: number;
  Co2Emission: number;
  RateScheduleType: string;
  CostSavings?: number;
  CarbonEmissionSavings?: number;
};

export type ChargingIntervalDTO = {
  ChargeIntervalID: string;
  StartTimeAtLocation: Date;
  EndTimeAtLocation: Date;
  StartStateOfCharge: number;
  EndStateOfCharge: number;
  EnergyCost?: number;
};

export type ChargeEnergySummaryDTO = {
  Date: Date;
  OnPeakEnergy: number;
  MidPeakEnergy: number;
  OffPeakEnergy: number;
  UnknownEnergy: number;
}

export type DemandResponseEventGroupMemberDTO = {
  AssociationId: string;
  DemandResponseEventId: string;
  FirstScheduledNotificationDateTimeUtc: Date;
  DemandResponseEventGroups: DemandResponseEventGroupDTO[];
  EmailAddress: string;
  ChargingLocation: ChargingLocationDTO;
  Device: DeviceDTO;
  OptedOut: boolean;
  OptOutDateTimeUtc: Date;
  OptOutReason: string;
  OptBackInDateTimeUtc: Date;
  IsParticipationPartial: boolean;
  ReasonUserNotParticipating: string;
  Errors: any[];
  WouldHaveCharged: boolean;
  StateOfChargePercentBeforeEvent: number;
  ChargingStateBeforeEvent: ChargingState;
  NotifiedEmailDateTimeUtc: Date;
  NotifiedSmsDateTimeUtc: Date;
  CreatedDateTimeUtc: Date;
};

export type DemandResponseEventNotificationDTO = {
  NotificationId: string;
  NotifyEmail: boolean;
  NotifySms: boolean;
  TimeUntilDemandResponseEvent: number;
  IsNotificationEnacted: boolean;
};

export type DemandResponseEarningsEntry = {
  Minutes: number;
  ValuePerMegawattHour: number;
};

export type DemandResponseEventGroupDTO = {
  GroupId: string;
  Name: string;
  UtilityName: string;
  ProgramCode: string;
  GroupType: string;
  GeoShapeFileSasUrl: string;
  ZipCodes: string[];
  CreatedByEmail: string;
  LastModifiedByEmail: string;
  CreatedDateTimeUtc: Date;
  LastModifiedDateTimeUtc: Date;
};

export type DemandResponseEventInfoDTO = {
  UtilityName: string;
  ProgramCode: string;
  StartTime: string;
  EndTime: string;
  IncreaseLoad: boolean;
  LoadTargetWatts: number;
  EventId: string;
  TimeAndDuration: string;
  AffectedGroups: DemandResponseEventGroupDTO[];
  Summary: string;
  Details: string;
  DisableNotifications: boolean;
  Earnings: DemandResponseEarningsEntry[];
  DemandResponseEventNotifications: DemandResponseEventNotificationDTO[];
};

export type DemandResponseEventAndMembersDTO = {
  DemandResponseEvent: DemandResponseEventInfoDTO;
  Members: DemandResponseEventGroupMemberDTO[];
};

export type NotSupportedReason = {
  FriendlyReason: string;
  TechnicalReason: string;
  IsRequired: boolean;
};

export type EngineType = "ICE" | "BEV" | "PHEV" | "HEV";

export type SmartcarApiVehicle = {
  SmartcarVehicleId: string;
  Make: string;
  Model: string;
  Year: number;
  VIN: string;
  BatteryCapacity?: number;
  AddedToUserAccount: boolean;
  IsMigrated?: boolean;
  EngineType?: EngineType;
  IsManagedChargingCapable?: boolean;
  NotManagedChargingSupportedReasons: NotSupportedReason[];
  VirtualKeyPaired: boolean;
};