import React, { ChangeEvent, useState } from "react";
import {
  AddLocationForm,
  AddLocationInputs,
} from "../components/AddLocationForm/AddLocationForm";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth/useAuth";
import { addChargingLocation, changeNotificationPhoneNumber, userChangeProgram } from "../apis/energyNetApi";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import { BasicPageHeader } from "../components/Shared/BasicPageHeader";
import { AddChargingLocationDTO, ChargingLocationTypeEnum } from "../apis/energyNetTypes";
import { useSignUpStep } from "../hooks/useSignUpStep/useSignUpStep";
import useBrandConfig from "../hooks/useBrandConfig";
import { t } from "i18next";
import { updateUserInfo } from "../store/userInfoSlice";
import { useDispatch } from "react-redux";
import { useTrackEvent } from "../hooks/useTrackEvent/useTrackEvent";
import { getGoogleLatLng } from "../hooks/useAddressAutoCompleteWidget/useAddressAutoCompleteWidget";

type AddLocationProps = {
  pillButton?: boolean;
  customAddLocationHandler?: (locationData: AddChargingLocationDTO) => void;
}

export const AddLocation = ({ pillButton, customAddLocationHandler }: AddLocationProps) => {
  const navigate = useNavigate();
  const config = useBrandConfig();
  const trackEvent = useTrackEvent();

  const { user } = useAuth();
  const [, setSignUpStep] = useSignUpStep();

  const [edited, setEdited] = useState(true);

  const [formInputs, setFormInputs] = useState<AddLocationInputs>({
    addressLineOne: "",
    city: "",
    state: "",
    postalCode: "",
    country: config.defaultCountry,
    utilityAccountId: "",
    phoneNumber: "",
    smsConsent: false,
  });

  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    if ((event.target as HTMLInputElement).setCustomValidity) {
      // clear the validation message (checkbox doesn't have this)
      (event.target as HTMLInputElement).setCustomValidity("");
    }
    setFormInputs({
      ...formInputs,
      [event.target.name]: event.target.value,
    });

    if (event.target.name !== "phoneNumber" && event.target.name !== "smsConsent" && event.target.name !== "utilityAccountId") {
      // lat lon could be wrong now
      setEdited(true);
    }
  };

  const handleAddressSelected = (address: AddLocationInputs) => {
    setFormInputs(prevState => ({
      ...address,
      phoneNumber: prevState.phoneNumber,
      smsConsent: prevState.smsConsent,
      utilityAccountId: prevState.utilityAccountId
    }));

    // lat lon should be accurate now
    setEdited(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setBusy(true);

    if (edited) {
      // if they edited the address manually, we need to resolve the lat lon in case it's wrong or empty
      const geo = await getGoogleLatLng(`${formInputs.addressLineOne}, ${formInputs.city}, ${formInputs.state}, ${formInputs.country}, ${formInputs.postalCode}`);

      if (geo) {
        formInputs.latitude = geo.latitude;
        formInputs.longitude = geo.longitude
      }
      else {
        formInputs.latitude = undefined;
        formInputs.longitude = undefined;
      }
    }

    if (user) {
      const chargingLocation: AddChargingLocationDTO = {
        name: "Home",
        chargingLocationType: ChargingLocationTypeEnum.Home,
        utilityName: config.utilityName,
        distributionUtilityName: config.distributionUtilityName,
        utilityRateScheduleName: config.rateSchedule,
        address: formInputs.addressLineOne?.trim(),
        city: formInputs.city?.trim(),
        state: formInputs.state?.trim(),
        country: formInputs.country?.trim(),
        postalCode: formInputs.postalCode?.trim(),
        notifyWhenDelayingCharging: true,
        notifyWhenNotPluggedIn: true,
        utilityAccountId: formInputs.utilityAccountId.trim(),
        latitude: formInputs.latitude,
        longitude: formInputs.longitude
      };

      try {
        await addChargingLocation(user, chargingLocation);

        // don't save the address to redux here, we'll reload if we need to

        trackEvent("AddChargingLocation", chargingLocation);

        try {
          if (formInputs.phoneNumber || formInputs.smsConsent) {
            const changePhoneResponse = await changeNotificationPhoneNumber(user, formInputs.phoneNumber, formInputs.smsConsent);

            // change phone number returns the updated userInfo
            dispatch(updateUserInfo(changePhoneResponse.data));
          }
        }
        catch (error) {
          console.log(`AddLocation: Error updating phone number: ${error}`);
        }

        if (customAddLocationHandler) {
          customAddLocationHandler(chargingLocation);
        }
        else {
          // send a welcome email
          await userChangeProgram(user, config.programName);

          setSignUpStep("locationadded");
          navigate("/locationadded");
        }
      } catch (error) {
        console.log(`error calling add charging location: ${error}`);
        setBusy(false);
      }
    } else {
      setBusy(false);
    }
  };

  return (
    <ResponsiveLayout>
      <>
        <BasicPageHeader title={t("Add Location")} />
        {config.addLocationText &&
          <p className={"text-sm mt-[20px]"}>{config.addLocationText}</p>
        }
        <div className={"form-contents-container pb-[20px]"}>
          <AddLocationForm
            inputs={formInputs}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onAddressSelected={handleAddressSelected}
            busy={busy}
            editExistingLocation={false}
            pillButton={pillButton}
          />
        </div>
      </>
    </ResponsiveLayout >
  );
};

