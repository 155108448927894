import { useNavigate } from "react-router-dom";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import { MulticolorHeadline } from "../components/Shared/MulticolorHeadline";
import { Button } from "../components/Shared/Button";
import { useSignUpStep } from "../hooks/useSignUpStep/useSignUpStep";
import useBrandConfig from "../hooks/useBrandConfig";
import { getImageURL } from "../brand-config";

const LocationAddedImage = ({ brand }: { brand: string }) => {
  const config = useBrandConfig();

  // make the the image shink sooner if the text is too long or the button will overlap it (eversource text)
  const hasLongText = config.locationAddedHeadlineOne.length + config.locationAddedHeadlineTwo.length > 75;

  // image that shrinks when the height gets too low.
  return (
    <img
      className={hasLongText ? "max-w-[308px] max-h-[calc(100vh-725px)]" : "max-w-[308px] max-h-[calc(100vh-525px)]"}
      alt={"Location Added"}
      src={getImageURL("addLocation", brand)}
    />
  );
};

export const LocationAdded = () => {
  const navigate = useNavigate();
  const config = useBrandConfig();

  const [, setSignUpStep] = useSignUpStep();

  const handleContinueClick = () => {
    setSignUpStep("welcome");
    navigate("/welcome");
  };

  return (
    <ResponsiveLayout>
      <>
        <MulticolorHeadline
          firstSentence={config.locationAddedHeadlineOne}
          secondSentence={config.locationAddedHeadlineTwo}
          breakBetween />
        <div className={"flex justify-center mt-[60px] md:mt-[75px]"}>
          <LocationAddedImage brand={config.programName} />
        </div>
        <div className={"mt-[42px] pb-[20px] flex justify-center bottom-pinned-button"}>
          <Button variant="primary" text={config.locationAddedButtonText} onClick={handleContinueClick} />
        </div>
      </>
    </ResponsiveLayout>
  );
};
