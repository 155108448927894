import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth/useAuth";
import { useEffect, useState } from "react";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import { MulticolorHeadline } from "../components/Shared/MulticolorHeadline";
import { Button } from "../components/Shared/Button";
import { useSignUpStep } from "../hooks/useSignUpStep/useSignUpStep";
import useBrandConfig from "../hooks/useBrandConfig";
import { getImageURL } from "../brand-config";
import { t } from "i18next";

const VehicleAddedImage = ({ brand }: { brand: string }) => {
  // image that shrinks when the height gets too low.
  return (
    <img
      className={"max-w-[308px] max-h-[calc(100vh-525px)]"}
      alt={t("Vehicle Added")}
      src={getImageURL("vehicleConnected", brand)}
    />
  );
};

export const VehicleAdded = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [isNavigating, setIsNavigating] = useState(false);
  const [signUpStep, setSignUpStep] = useSignUpStep();
  const config = useBrandConfig();

  useEffect(() => {
    if (signUpStep) {
      setSignUpStep("vehicleadded");
    }
  }, [signUpStep, setSignUpStep]);

  const handleContinueClick = () => {
    if (user) {
      setIsNavigating(true);
      setSignUpStep("locationinstructions");
      navigate("/locationinstructions")
    }
  };

  return (
    <ResponsiveLayout>
      <>
        <MulticolorHeadline
          firstSentence={config.addVehicleHeadlineOne}
          secondSentence={config.addVehicleHeadlineTwo}
          breakBetween />
        <div className={"flex justify-center mt-[60px] md:mt-[75px]"}>
          <VehicleAddedImage brand={config.programName} />
        </div>

        <div className={"mt-[42px] pb-[20px] flex justify-center bottom-pinned-button"}>
          <Button
            variant="primary"
            text={isNavigating ? t("Continuing") : t("Continue")}
            busy={isNavigating}
            onClick={handleContinueClick} />
        </div>
      </>
    </ResponsiveLayout>
  );
};
