import axios, { AxiosError } from "axios";
import {
  ADD_CHARGING_LOCATION_URL,
  GET_CHARGING_LOCATIONS_URL,
  GET_DEVICE_STATUS_URL,
  GET_DEVICES_URL,
  LOG_IN_URL,
  LOG_OUT_URL,
  REQUEST_PASSWORD_RESET_URL,
  SIGN_UP_URL,
  SIGN_UP_URL_RECAPTCHA,
  DELETE_DEVICE_URL,
  GET_DEVICE_PREFERENCES_URL,
  GET_OPT_IN_AFTER_URL,
  SET_DEVICE_PREFERENCES_URL,
  SET_OPT_IN_AFTER_URL,
  VERIFY_EMAIL_URL,
  ADD_UNSUPPORTED_EV_URL,
  USER_INFO_URL,
  CHANGE_PROGRAM_URL,
  SEND_ACCESS_TOKEN,
  GET_STATUS_URL,
  GET_DEVICE_ERRORS_URL,
  GET_DEVICE_CHARGING_SESSIONS_URL,
  GET_CHARGING_INTERVALS_URL,
  GET_CHARGING_ENERGY_SUMMARY_URL,
  GET_TESLA_TOKENS_URL,
  USER_CHANGE_NOTIFICATION_PHONE_URL,
  GET_CAR_STATE_URL,
  DELETE_MY_DATA_URL,
  ADD_USER_REFERRAL,
  GET_DEMAND_RESPONSE_EVENT_URL,
  GET_DEMAND_RESPONSE_EVENTS_BY_DATE_URL,
  PUT_DEMAND_RESPONSE_EVENTS_OPT_OUT_ALL,
  GET_SMARTCAR_VIRTUAL_KEY_PAIRED_STATUS,
  SEND_AUTH_CODE,
} from "../constants/energyNetApi";
import getEnv from "../env";
import { tokenKey } from "../hooks/useAuth/useAuth";
import { AddChargingLocationDTO, CarStateDTO, ChargeEnergySummaryDTO, ChargingIntervalDTO, ChargingLocationDTO, ChargingSessionDTO, DemandResponseEventAndMembersDTO, DeviceDTO, DeviceErrorDTO, DevicePreferencesDTO, DeviceStatusDTO, DisableSmartChargingDTO, SmartcarApiVehicle, UnsupportedEVDTO, UserInfoDTO } from "./energyNetTypes";
import { trackError } from "./tracking";
import { format } from "date-fns";

const BASIC_AUTH_USERNAME =
  getEnv().ENERGY_NET_API_BASIC_AUTH_USERNAME || "some-basic-auth";
const BASIC_AUTH_PASSWORD =
  getEnv().ENERGY_NET_API_BASIC_AUTH_PASSWORD || "some-basic-password";

export type SignUpData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  program: string;
  driverCultureName: string;
  useMetric: boolean;
  isTestUser: boolean;
  recaptchaToken?: string;
};

// see https://typescript.tv/best-practices/error-ts1196-catch-clause-variable-type-annotation/
export function isAxiosError(candidate: unknown): candidate is AxiosError {
  if (candidate && typeof candidate === 'object' && 'isAxiosError' in candidate) {
    return true;
  }
  return false;
}

// Automatically log out if any api returns unauthorized (401)
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      const token = localStorage.getItem(tokenKey);
      if (token !== null && token !== 'null') {
        // don't do a redirect when user is already signed out - that will erase the login form
        localStorage.removeItem(tokenKey);
        window.location.assign("/login");
      }
    }
    return Promise.reject(error);
  }
);

/*
// Show all requests in console for troubleshooting
axios.interceptors.request.use(request => {
  //console.log('Starting Request', JSON.stringify(request, null, 2))
  console.log('Starting Request', request);
  return request
});
*/

const logError = (url: string, error: any) => {
  trackError(`API Error - url:'${url}'`, error);
};

export const validateEmail = async (email: string) => {
  return await axios.post(VERIFY_EMAIL_URL, { email }, {
    auth: {
      username: BASIC_AUTH_USERNAME,
      password: BASIC_AUTH_PASSWORD,
    },
  });
};

export const userSignUp = async (signUpData: SignUpData) => {
  return await axios.post(signUpData.recaptchaToken ? SIGN_UP_URL_RECAPTCHA : SIGN_UP_URL, signUpData, {
    auth: {
      username: BASIC_AUTH_USERNAME,
      password: BASIC_AUTH_PASSWORD,
    },
  });
};

export const userLogin = (email: string, password: string) => {
  return axios.post(
    LOG_IN_URL,
    {
      email: email,
      password: password,
    },
    {
      auth: {
        username: BASIC_AUTH_USERNAME,
        password: BASIC_AUTH_PASSWORD,
      },
    }
  );
};

// This api also sends a email that the user have been activated, even if the program is unchanged.
export const userChangeProgram = (userToken: string, program: string) => {
  return axios.post(
    CHANGE_PROGRAM_URL,
    {
      program: program,
    },
    { headers: { Authorization: `Bearer ${userToken}` } }
  );
};

export const getUserInfo = (userToken: string) => {
  return axios.get<UserInfoDTO>(USER_INFO_URL, {
    headers: { Authorization: `Bearer ${userToken}` },
  });
};

export const changeNotificationPhoneNumber = (userToken: string, phoneNumber: string, smsConsent: boolean) => {
  return axios.post<UserInfoDTO>(
    USER_CHANGE_NOTIFICATION_PHONE_URL,
    {
      phoneNumber: phoneNumber,
      enableSMSNotifications: smsConsent,
    },
    {
      headers: { Authorization: `Bearer ${userToken}` }
    }
  )
}

export const requestPasswordReset = (email: string) => {
  try {
    return axios.post(
      REQUEST_PASSWORD_RESET_URL,
      {
        email: email,
      },
      {
        auth: {
          username: BASIC_AUTH_USERNAME,
          password: BASIC_AUTH_PASSWORD,
        },
      }
    );
  }
  catch (error) {
    logError(REQUEST_PASSWORD_RESET_URL, error);
  }
};

export const userLogout = (userToken: string) => {
  return axios.get(LOG_OUT_URL, {
    headers: { Authorization: `Bearer ${userToken}` },
  });
};

export const getDevices = (userToken: string) => {
  try {
    return axios.get<DeviceDTO[]>(GET_DEVICES_URL, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(GET_DEVICES_URL, error);
    throw error;
  }
};

export const getDeviceStatus = (userToken: string, deviceGuid: String) => {
  const deviceStatusUrl = `${GET_DEVICE_STATUS_URL}/${deviceGuid}`;

  try {
    return axios.get<DeviceStatusDTO[]>(deviceStatusUrl, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(deviceStatusUrl, error);
    throw error;
  }
};


export const getStatus = (userToken: string, deviceGuid: String) => {
  const deviceStatusUrl = `${GET_STATUS_URL}/${deviceGuid}`;

  try {
    return axios.get<DeviceStatusDTO>(deviceStatusUrl, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(deviceStatusUrl, error);
    throw error;
  }
};

export const getCarState = (userToken: string, deviceGuid: String) => {
  const getCarStateUrl = `${GET_CAR_STATE_URL}/${deviceGuid}`;

  try {
    return axios.get<CarStateDTO>(getCarStateUrl, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(getCarStateUrl, error);
    throw error;
  }
};

export const deleteDevice = (userToken: string, id: string) => {
  const deleteDeviceUrl = `${DELETE_DEVICE_URL}/${id}`;
  try {
    return axios.get(deleteDeviceUrl, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(deleteDeviceUrl, error);
    throw error;
  }
};

export const GetDevicePreferences = (userToken: string, deviceGuid: String) => {
  const devicePrefererencesUrl = `${GET_DEVICE_PREFERENCES_URL}/${deviceGuid}`;
  try {
    return axios.get<DevicePreferencesDTO>(devicePrefererencesUrl, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(devicePrefererencesUrl, error);
    throw error;
  }
};

export const SetDevicePreferences = (userToken: string, deviceGuid: String, devicePreferences: DevicePreferencesDTO) => {
  const devicePrefererencesUrl = `${SET_DEVICE_PREFERENCES_URL}`;
  try {
    return axios.post(devicePrefererencesUrl, devicePreferences, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(devicePrefererencesUrl, error);
    throw error;
  }
};

export const GetOptInAfter = (userToken: string, deviceGuid: String) => {
  const GetOptInAfterUrl = `${GET_OPT_IN_AFTER_URL}/${deviceGuid}`;
  try {
    return axios.get<DisableSmartChargingDTO>(GetOptInAfterUrl, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(GetOptInAfterUrl, error);
    throw error;
  }
};

export const SetOptInAfter = (userToken: string, optInAfter: DisableSmartChargingDTO) => {
  const SetOptInAfterUrl = `${SET_OPT_IN_AFTER_URL}`;
  try {
    return axios.post(SetOptInAfterUrl, optInAfter, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(SetOptInAfterUrl, error);
    throw error;
  }
};

export const DeleteMyData = (userToken: string, hardDelete: boolean = false, anonymize: boolean = false) => {
  try {
    return axios.post(DELETE_MY_DATA_URL,
      {
        hardDelete: hardDelete,
        anonymize: anonymize,
      },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      });
  } catch (error) {
    logError(DELETE_MY_DATA_URL, error);
    throw error;
  }
};

export const addUnsupportedEV = (userToken: string, unsupportedEV: UnsupportedEVDTO) => {
  const AddUnsupportedEVUrl = `${ADD_UNSUPPORTED_EV_URL}`;
  try {
    return axios.post(AddUnsupportedEVUrl, unsupportedEV, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(AddUnsupportedEVUrl, error);
    throw error;
  }
};

export const getChargingLocations = async (userToken: string) => {
  try {
    return await axios.get<ChargingLocationDTO[]>(GET_CHARGING_LOCATIONS_URL, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(GET_CHARGING_LOCATIONS_URL, error);
    throw error;
  }
};

export enum CarPollingSource {
  /// <summary>
  /// Unknown token source
  /// </summary>
  Unknown = 0,

  /// <summary>
  /// Tesla tokens owner's api tokens.
  /// </summary>
  Tesla = 1,

  /// <summary>
  /// Smartcar tokens api tokens.
  /// </summary>
  SmartCar = 2,

  /// <summary>
  /// Nissan tokens.
  /// </summary>
  Nissan = 3,

  /// <summary>
  /// Testing purposes only. 
  /// </summary>
  Mock = 4,

  /// <summary>
  /// Tesla Fleet api tokens.
  /// </summary>
  TeslaFleet = 5,
};

export const sendAccessToken = async (user: string, code: string, make: string, redirectUri: string, isLive: boolean, codeVerifier: string, pollingSource: CarPollingSource) => {
  try {
    return await axios.post(
      SEND_ACCESS_TOKEN,
      { // public class AccessCodeDTO
        carMaker: make,
        accessCode: code,
        redirectUri,
        codeVerifier,
        polling: pollingSource,
        isLive,
      },
      {
        headers: { Authorization: `Bearer ${user}` },
      }
    );
  } catch (error) {
    logError(SEND_ACCESS_TOKEN + code, error);
    throw error;
  }
};


export const sendAccessCode = async (user: string, code: string, make: string, redirectUri: string, isLive: boolean, pollingSource: CarPollingSource) => {
  try {
    return await axios.post<SmartcarApiVehicle>(
      SEND_AUTH_CODE,
      { // public class AccessCodeDTO
        carMaker: make,
        accessCode: code,
        redirectUri: redirectUri,
        polling: pollingSource,
        isLive,
      },
      {
        headers: { Authorization: `Bearer ${user}` },
      }
    );
  } catch (error) {
    logError(SEND_AUTH_CODE + code, error);
    throw error;
  }
};

export const addChargingLocation = async (
  userToken: string,
  chargingLocation: AddChargingLocationDTO
) => {
  try {
    return await axios.post(ADD_CHARGING_LOCATION_URL, chargingLocation, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(ADD_CHARGING_LOCATION_URL, error);
    throw error;
  }
};

export const getDeviceErrors = async (userToken: string) => {
  try {
    return await axios.get<DeviceErrorDTO>(GET_DEVICE_ERRORS_URL, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(GET_DEVICE_ERRORS_URL, error);
    throw error;
  }
};

export const getTeslaTokens = async (userToken: string, redirectUrl: string, verifier: string) => {
  try {
    return await axios.post(GET_TESLA_TOKENS_URL,
      {
        redirectUrl: redirectUrl,
        codeVerifier: verifier,
      },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
  } catch (error) {
    logError(GET_TESLA_TOKENS_URL, error);
    throw error;
  }
};
export const getDeviceChargingSessions = async (userToken: string, deviceGuid: string) => {
  try {
    return await axios.get<ChargingSessionDTO[]>(`${GET_DEVICE_CHARGING_SESSIONS_URL}/${deviceGuid}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(GET_DEVICE_CHARGING_SESSIONS_URL, error);
    throw error;
  }
};

export const getChargingIntervals = async (userToken: string, chargeSessionId: string) => {
  try {
    return await axios.get<ChargingIntervalDTO[]>(`${GET_CHARGING_INTERVALS_URL}/${chargeSessionId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(GET_CHARGING_INTERVALS_URL, error);
    throw error;
  }
};

export const GetChargingEnergySummary = async (userToken: string, interval: "daily" | "monthly", deviceGuid: string, startDate: Date, endDate: Date) => {
  try {
    const startDateString = format(startDate, "yyyy-MM-dd");
    const endDateString = format(endDate, "yyyy-MM-dd");
    return await axios.get<ChargeEnergySummaryDTO[]>(`${GET_CHARGING_ENERGY_SUMMARY_URL}/${interval}/${deviceGuid}/${startDateString}/${endDateString}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(GET_CHARGING_ENERGY_SUMMARY_URL, error);
    throw error;
  }
}





export const AddUserReferral = async (userToken: string, email: string, utilityName: string, programCode: string, referralEmail: string) => {
  const userReferralDTO = {
    referredByEmailAddress: email,
    newUserEmailAddress: referralEmail,
    utilityName,
    programCode
  };

  try {
    return await axios.post(ADD_USER_REFERRAL, userReferralDTO, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  } catch (error) {
    logError(ADD_USER_REFERRAL, error);
    throw error;
  }
};

export const GetDemandResponseEvents = async (userToken: string) => {
  try {
    return await axios.get<DemandResponseEventAndMembersDTO[]>(`${GET_DEMAND_RESPONSE_EVENT_URL}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  }
  catch (error) {
    logError(GET_DEMAND_RESPONSE_EVENT_URL, error);
    throw error;
  }
};

export const GetDemandResponseEventsByDate = async (userToken: string, startDate: Date, endDate: Date) => {
  let url = "";
  try {
    const startDateString = format(startDate, "yyyy-MM-dd");
    const endDateString = format(endDate, "yyyy-MM-dd");
    url = GET_DEMAND_RESPONSE_EVENTS_BY_DATE_URL(startDateString, endDateString);
    return await axios.get<DemandResponseEventAndMembersDTO[]>(url, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  }
  catch (error) {
    logError(url, error);
    throw error;
  }
};

export const PutDemandResponseEventsOptOut = async (userToken: string, eventId: string, optOut: boolean) => {
  let url = "";
  try {
    url = PUT_DEMAND_RESPONSE_EVENTS_OPT_OUT_ALL(eventId, optOut);
    return await axios.put(url, {}, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  }
  catch (error) {
    logError(url, error);
    throw error;
  }
};

// check the status of the tesla virtual key pairing
// returns true if the virtual key is paired, false if it is not paired, and null if they car doesn't support virtual keys
export const GetSmartcarVirtualKeyPairedStatus = async (userToken: string, smartcarVehicleId: string) => {
  try {
    return await axios.get<boolean | null>(`${GET_SMARTCAR_VIRTUAL_KEY_PAIRED_STATUS}?smartcarVehicleId=${smartcarVehicleId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  }
  catch (error) {
    logError(GET_SMARTCAR_VIRTUAL_KEY_PAIRED_STATUS, error);
    throw error;
  }
};