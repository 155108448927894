import { QueryString } from "../../constants/queryString";
import getEnv from "../../env";
import useBrandConfig from "../useBrandConfig";

// addvehicle happens on a different domain in prod and dev https and this hook returns the correct url or path for the current environment
// if redirect is true, its going to a different domain cannot be done with react router
export const useAddVehicleUrl = (user: string | null, duringSignUp: boolean, reconnectMake: string | null = null) => {
  const env = getEnv().ENVIRONMENT;
  const config = useBrandConfig();

  if (!user) return null;

  if ((env === "production" || (env === "development" && window.location.protocol === "https:"))
    && window.location.hostname !== "evision.flexcharging.com") {
    // production add vehicle runs on a generic domain to allows us to use generic redirects for auth flows
    return {
      redirect: true,
      url: `https://evision.flexcharging.com/addvehicle?user=${encodeURIComponent(user)}&brand=${config.programName}${duringSignUp ? ("&" + QueryString.SignUp) : ""}${reconnectMake ? ("&" + QueryString.Reconnect + "=" + reconnectMake) : ""}`
    };
  }
  else {
    return { redirect: false, url: `/addvehicle${duringSignUp ? ("?" + QueryString.SignUp) : ""}` };
  }
}