import { useEffect, useState, useRef, useCallback, Dispatch, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { useAuth } from "../hooks/useAuth/useAuth";
import { useTranslation } from 'react-i18next';
import {
  deleteDevice,
  getChargingLocations,
  GetDevicePreferences,
  SetDevicePreferences,
  getDevices,
  getCarState,
  GetOptInAfter,
  SetOptInAfter,
  GetChargingEnergySummary,
  GetDemandResponseEventsByDate,
  PutDemandResponseEventsOptOut,
  GetSmartcarVirtualKeyPairedStatus
} from "../apis/energyNetApi";
import { updateCars, updateCarsLoading, updateFirstManagableCar, updateManagedChargingEnabled, updateOptOutEnabled, useCars, useCarsLoading, useFirstManagableCar, useManagedChargingEnabled, useOptOutEnabled } from "../store/carSlice";
import { updateChargingLocation, useChargingLocation, updateChargingLocationLoading, useChargingLocationLoading } from "../store/chargingLocationSlice";
import { DashboardDevice } from "../components/DashboardDevice/DashboardDevice";
import accountPageButton from "../static/ui/adminIcon.svg";
import warningIcon from "../static/ui/warningIcon.svg";
import { useNavigate } from "react-router-dom";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import { BasicPageHeader } from "../components/Shared/BasicPageHeader";
import { PillButton } from "../components/Shared/PillButton";
import Modal from "../components/Shared/Modal";
import { Button } from "../components/Shared/Button";
import { CarStateDTO, ChargeEnergySummaryDTO, DemandResponseEventAndMembersDTO, DemandResponseEventGroupMemberDTO, DemandResponseEventInfoDTO, DeviceErrorDTO, DevicePreferencesDTO, DisableSmartChargingDTO } from "../apis/energyNetTypes";
import { Spinner } from "../components/Shared/Spinner";
import { useSignUpStep } from "../hooks/useSignUpStep/useSignUpStep";
import useBrandConfig from "../hooks/useBrandConfig";
import { DashboardManageCharging } from "../components/DashboardManageCharging/DashboardManageCharging";
import { getLogo } from "../helpers/getLogo";
import { Car } from "../store/types/car";
import { useTrackEvent } from "../hooks/useTrackEvent/useTrackEvent";
import DemandResponseEvent from "../components/DemandResponse/DemandResponseEvent";
import IconButton from "../components/Shared/IconButton";
import DemandResponseInfoModal from "../components/DemandResponse/DemandResponseInfoModal";
import { updateDemandResponseEventAndMembers, updateDemandResponseEventOptOut, useDemandResponseEventAndMembers } from "../store/demandResponseSlice";
import { GenerateRandomChargingEnergySummary } from "../apis/randomApi";
import { AnyAction } from "redux";
import VirtualKeyInstructionsModal from "../components/VirtualKeyInstructions/VirtualKeyInstructionsModal";
import VirtualKeyInstructionsMessage from "../components/VirtualKeyInstructions/VirtualKeyInstructionsMessage";
import { addDeviceKeys, DeviceKeyState, useVirtualKeys } from "../store/virtualKeySlice";

const getFirstDayOfNextMonth = () => {
  const today = new Date();
  let nextMonthYear = today.getFullYear();
  let nextMonth = today.getMonth() + 1;

  if (nextMonth === 12) {
    nextMonth = 0; // January
    nextMonthYear++;
  }
  return new Date(nextMonthYear, nextMonth, 1);
}

const getFirstDayOf11MonthsAgo = () => {
  const today = new Date();
  let targetMonth = today.getMonth() - 11;
  let targetYear = today.getFullYear();

  // Adjust year if the target month is negative
  if (targetMonth < 0) {
    targetMonth += 12; // Wrap around to previous year
    targetYear--;
  }

  return new Date(targetYear, targetMonth, 1);
}

const addFakeDemandResponseEvent = (dispatch: Dispatch<AnyAction>) => {
  const fakeDemandResponse: DemandResponseEventAndMembersDTO[] = [];
  const startDate = new Date();
  startDate.setDate(startDate.getDate() + 1 + Math.floor(Math.random() * 3));
  startDate.setHours(12 + Math.floor(Math.random() * 6));
  startDate.setMinutes(Math.floor(Math.random() * 4) * 15);
  const endDate = new Date(startDate);
  endDate.setHours(endDate.getHours() + 2 + Math.floor(Math.random() * 6));
  endDate.setMinutes(Math.floor(Math.random() * 4) * 15);
  fakeDemandResponse.push({
    DemandResponseEvent: { EventId: "1", StartTime: startDate, EndTime: endDate } as any as DemandResponseEventInfoDTO,
    Members: [{ OptedOut: false } as any as DemandResponseEventGroupMemberDTO]
  });

  // save it so we only have to do this once and it saves the opt state
  dispatch(updateDemandResponseEventAndMembers(fakeDemandResponse));
};

export const Dashboard = () => {
  const { user, email, isImpersonating } = useAuth();
  const navigate = useNavigate();
  const [signUpStep, setSignUpStep] = useSignUpStep();
  const config = useBrandConfig();
  const [deleteId, setDeleteId] = useState("");
  const deleteModalButtonRef = useRef<HTMLButtonElement>(null);
  const demandResponseModalButtonRef = useRef<HTMLButtonElement>(null);
  const virtualKeyModelButtonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const cars = useCars();
  const managedChargingEnabled = useManagedChargingEnabled();
  const optOutEnabled = useOptOutEnabled();
  const firstManagableCar = useFirstManagableCar();
  const carsLoading = useCarsLoading();
  const chargingLocationLoading = useChargingLocationLoading();
  const chargingLocation = useChargingLocation();
  let demandResponse = useDemandResponseEventAndMembers();
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const [loadError, setLoadError] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const teslaVirtualKeyPaired = useVirtualKeys();

  // feature to have a button to override smart charging for 12 hours
  const enableOverride = config.dashboardEnableOverride;

  useEffect(() => {
    // in case some manually navigated here during signup
    if (user && signUpStep && !isImpersonating) {
      navigate(`/${signUpStep}`);
    }
  }, [user, signUpStep, navigate, isImpersonating]);

  const loadDeviceData = useCallback(async () => {
    if (!user) {
      return;
    }

    // reload if we don't have cars or charging location. its possible to have one and not the other.
    if (cars !== undefined && chargingLocation !== undefined) {
      return;
    }

    dispatch(updateCarsLoading(true));
    dispatch(updateChargingLocationLoading(true));
    dispatch(updateFirstManagableCar(undefined));
    setHasLoaded(true);
    const startTime = Date.now();
    getDevices(user)
      .then((response) => {
        let carList: Car[] = [];
        let promises: Promise<any>[] = [];

        if (response.data.length > 0) {
          let responseDevices = response.data;

          // set the logo and car type
          carList = responseDevices
            .filter((device) => !device.FriendlyName?.startsWith("DEMO EV")) // demo device no supported here
            .map((device) => {
              return {
                device: device,
                isSimulated: !!(device?.UniqueId?.startsWith("0SC") || device?.UniqueId?.startsWith("1SC"))
              } as Car
            });

          // now load all other data concurrently
          const twentyFourHoursMs = 3600000 * 24;
          let carPromises = carList.map((car) => {

            const vehicleAddTime = car.device.CreatedTimeUTC ? new Date(car.device.CreatedTimeUTC) : undefined;
            const addedLessThan24HoursAgo = vehicleAddTime && (Date.now() - vehicleAddTime.getTime()) < twentyFourHoursMs;
            return getCarState(user, car.device.GUID)
              .then((response) => {
                const carState = response.data as CarStateDTO;

                if (carState) {
                  car.state = carState;
                  // simulated cars don't have a status so always show connected        
                  // these are translated inside DashboardDevice                          

                  // Always show "Connected" for a car 1 day after it was seen. 
                  const isDeviceConnected = car.state.LastSeenTime && (Date.now() - new Date(car.state.LastSeenTime).getTime()) < twentyFourHoursMs;
                  car.statusString = (car.isSimulated || isDeviceConnected || addedLessThan24HoursAgo)
                    ? "Connected" : "Disconnected";
                } else {
                  // car state won't have a value initially so just base it on time added
                  car.statusString = (car.isSimulated || addedLessThan24HoursAgo)
                    ? "Connected" : "Disconnected";
                }
              })
              .catch((reason) => {
                // api will return a 404 if the has never gotten data
                if (car.isSimulated || addedLessThan24HoursAgo) {
                  // simulated cars get lots of error from the real smartcar call in getDeviceStatus and we'll ignore them
                  car.statusString = "Connected";
                  return car;
                }
                car.statusString = "Disconnected";
                return car;
              });
          });

          promises = promises.concat(carPromises);
        }

        if (enableOverride) {
          const preferencePromises = carList.map((car) => {
            return GetDevicePreferences(user, car.device.GUID)
              .then((response) => {
                const devicePreferences = response.data as DevicePreferencesDTO;
                car.preferences = devicePreferences;
              })
              .catch((error) => {
                setLoadError(true);
                console.log(`error calling GetDevicePreferences: ${error}`);
                dispatch(updateCarsLoading(false));
              });
          });

          promises = promises.concat(preferencePromises);

          const optInPromises = carList.map((car) => {
            return GetOptInAfter(user, car.device.GUID)
              .then((response) => {
                const disableSmartCharging = response.data as DisableSmartChargingDTO;
                car.disableSmartCharging = disableSmartCharging;
              })
              .catch((error) => {
                setLoadError(true);
                console.log(`error calling GetOptInAfter: ${error}`);
                dispatch(updateCarsLoading(false));
              });
          });
          promises = promises.concat(optInPromises);
        }


        if (config.dashboardChargingCharts) {
          // load energy char data 
          const dailyChargingPromises = carList.map((car) => {
            const endDate = new Date();
            const startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 7);

            if (car.isSimulated) {
              return GenerateRandomChargingEnergySummary(startDate, endDate, "daily", car.device.UniqueId)
                .then((response) => {
                  car.dailyChargeEnergySummary = response;
                });
            }

            return GetChargingEnergySummary(user, "daily", car.device.GUID, startDate, endDate)
              .then((response) => {
                car.dailyChargeEnergySummary = response.data.map((energy) => {
                  return {
                    Date: new Date(energy.Date),
                    UnknownEnergy: energy.UnknownEnergy,
                    OnPeakEnergy: energy.OnPeakEnergy,
                    OffPeakEnergy: energy.OffPeakEnergy,
                  } as ChargeEnergySummaryDTO;
                });
              })
              .catch((error) => {
                console.log(`error calling GetDailyChargingEnergySummary: ${error}`);
                dispatch(updateCarsLoading(false));
              });
          });
          promises = promises.concat(dailyChargingPromises);

          const monthlyChargingPromises = carList.map((car) => {
            // get first day next month
            const endDate = getFirstDayOfNextMonth();
            const startDate = getFirstDayOf11MonthsAgo();

            if (car.isSimulated) {
              return GenerateRandomChargingEnergySummary(startDate, endDate, "monthly", car.device.UniqueId)
                .then((response) => {
                  car.monthlyChargeEnergySummary = response;
                });
            }

            return GetChargingEnergySummary(user, "monthly", car.device.GUID, startDate, endDate)
              .then((response) => {
                car.monthlyChargeEnergySummary = response.data.map((energy) => {
                  return {
                    Date: new Date(energy.Date),
                    UnknownEnergy: energy.UnknownEnergy,
                    OnPeakEnergy: energy.OnPeakEnergy,
                    OffPeakEnergy: energy.OffPeakEnergy,
                  } as ChargeEnergySummaryDTO;
                });
              })
              .catch((error) => {
                console.log(`error calling GetDailyChargingEnergySummary: ${error}`);
                dispatch(updateCarsLoading(false));
              });
          });
          promises = promises.concat(monthlyChargingPromises);
        }

        const locationPromise = getChargingLocations(user)
          .then((response) => {
            if (response.data.length > 0) {
              dispatch(updateChargingLocation(response.data[0]));
            }
            dispatch(updateChargingLocationLoading(false));
          })
          .catch((error) => {
            setLoadError(true);
            dispatch(updateChargingLocationLoading(false));
            console.log(
              `error calling getChargingLocations: ${error}`
            );
          });
        promises.push(locationPromise);

        const currentDate = new Date();
        const sevenDaysLater = new Date();
        sevenDaysLater.setDate(currentDate.getDate() + 7);

        const demandResponsePromise = GetDemandResponseEventsByDate(user, currentDate, sevenDaysLater)
          .then((response) => {
            if (response.data) {
              // they don't always come back in order, want the soonest first
              const sortedEvents = response.data.sort((a, b) =>
                a.DemandResponseEvent.StartTime.localeCompare(b.DemandResponseEvent.StartTime)
              );

              dispatch(updateDemandResponseEventAndMembers(sortedEvents));
            }
          })
          .catch((error) => {
            console.log(
              `error calling getDemandResponseEvents: ${error}`
            );
          });
        promises.push(demandResponsePromise);

        let deviceErrors: DeviceErrorDTO = { HasError: false, StatusMessages: [] };
        /* // this is slow take it out for now
        const deviceErrorsPromise = getDeviceErrors(user)
          .then((response) => {
            if (response.data?.StatusMessages?.length > 0) {
              deviceErrors = response.data;
              deviceErrors.StatusMessages.forEach((error) => {
                if (error.lastMessageDateTime) {
                  error.lastMessageDateTime = new Date(error.lastMessageDateTime);
                }
              });
            }
          })
          .catch((error) => {
            console.log(
              `error calling getDeviceErrors: ${error}`
            );
          });
        promises.push(deviceErrorsPromise);
        */

        Promise.all(promises).then(() => {
          // all data is loaded, calculate some values and then put the car list in the state 
          carList.forEach((car) => {
            if (car.disableSmartCharging) {
              const totalHours = car.disableSmartCharging!.TotalHours ? car.disableSmartCharging!.TotalHours : 0;
              car.overrideAvailable = car.device.CanDeviceStartAndStopCharging;
              car.managedChargingEnabled = !car.preferences!.DisableSmartCharging
              car.optOutEnabled = totalHours > 0;
            }
            if (deviceErrors && carList.length === 1) {
              car.errors = deviceErrors.StatusMessages; // TODO - filter by device. we currently don't have a device id on the error
            }
          }
          );
          const firstCar = carList.length > 0 ? carList.find(c => c.overrideAvailable) : undefined
          dispatch(updateFirstManagableCar(firstCar));
          if (firstCar) {
            dispatch(updateManagedChargingEnabled(firstCar.managedChargingEnabled));
            dispatch(updateOptOutEnabled(firstCar.optOutEnabled));
          }
          dispatch(updateCars(carList));
          dispatch(updateCarsLoading(false));

          const loadTime = Date.now() - startTime;
          console.log("dashboard load time: " + loadTime + "ms");
        });

      })
      .catch((error) => {
        setLoadError(true);
        console.log(`error calling getDevices: ${error}`);
        dispatch(updateCarsLoading(false));
      });
  }, [enableOverride, dispatch, user, cars, config.dashboardChargingCharts]);

  // trick to load data only once in StrictMode across mounts
  const okToLoadDeviceData = useRef(true)
  useEffect(() => {
    if (okToLoadDeviceData.current) {
      okToLoadDeviceData.current = false;
      loadDeviceData();
    }
  }, [loadDeviceData]);

  // check if we need to return to the signup flow, we can tell my what data is missing
  // make sure that a data error doesn't do this as well
  useEffect(() => {
    if (hasLoaded && !carsLoading && !chargingLocationLoading && !chargingLocation && !loadError && email) {
      if (cars && cars.length === 0) {
        trackEvent("RedirectDashboardToAccountCreated");
        if (!isImpersonating) {
          setSignUpStep("accountcreated");
        }
        navigate(`/accountcreated`);
      }
      else {
        trackEvent("RedirectDashboardToLocationInstructions");
        if (!isImpersonating) {
          setSignUpStep("locationinstructions");
        }
        navigate(`/locationinstructions`);
      }
      return;
    }
  }, [chargingLocation, cars, navigate, setSignUpStep, carsLoading, chargingLocationLoading, loadError, email, trackEvent, hasLoaded, isImpersonating]);

  useEffect(() => {
    // user should be logged in. check email not null or trackEvent will cause double call
    if (email && cars && cars.length === 1) {
      const car = cars[0];
      if (car?.errors && car?.statusString === "Disconnected") {
        const authErrors = car.errors.filter(
          (error) => {
            return error.Status === "AUTHENTICATION" && error.lastMessageDateTime &&
              (new Date().getTime() - error.lastMessageDateTime.getTime()) < (24 * 60 * 60 * 1000)
          });

        if (authErrors.length > 0) {
          trackEvent("authErrorReconnectPrompt", { "device": car.device, "error": authErrors })
        }
      }
    }
  }, [cars, trackEvent, email]);

  // check virtual key status on any teslas
  useEffect(() => {
    // this setting allows sites to opt out of showing the message
    if (!cars || !user || !config.dashboardShowVirtualKeyMessage) return;
    if (teslaVirtualKeyPaired && teslaVirtualKeyPaired.length > 0) return; // already did this.

    const teslas = cars?.filter((car) => car.device.Make === "TESLA");
    if (teslas && user) {
      const teslaKeyStates: DeviceKeyState[] = [];
      for (const i in teslas) {
        const tesla = teslas[i].device;
        const smartCarId = tesla.SmartCarID;

        // these models don't support virtual key until the api can do this for us. href="https://www.tesla.com/support/tesla-vehicle-keys
        if ((tesla.ModelName?.toUpperCase() === "MODEL S" && tesla.ModelYear && tesla.ModelYear >= 2012 && tesla.ModelYear <= 2020) ||
          (tesla.ModelName?.toUpperCase() === "MODEL X" && tesla.ModelYear && tesla.ModelYear >= 2015 && tesla.ModelYear <= 2020)) {
          continue;
        }

        GetSmartcarVirtualKeyPairedStatus(user, smartCarId)
          .then((response) => {
            const isPaired = response.data;
            trackEvent("  API", { "device": smartCarId, "isPaired": isPaired });
            teslaKeyStates.push({ device: tesla, paired: isPaired, keyNeeded: isPaired === false }); // testing

            // this redux slice has only a five minute cache so it will refresh on its own
            dispatch(addDeviceKeys(teslaKeyStates));
          })
          .catch((error) => {
            trackEvent("TeslaVirtualKeyPairedAPI", { "device": smartCarId, "error": error });
          });
      }
    }
  }, [cars, user, trackEvent, dispatch, teslaVirtualKeyPaired, config.dashboardShowVirtualKeyMessage]);

  const demandResponseEventsVaryByCar = useMemo(() => {
    if (!demandResponse || demandResponse.length <= 1) {
      return false
    }

    const demandResponseDeviceLists = demandResponse.map((event) => event.Members.map((member) => member.Device.HashedUniqueID));

    // check if every list in deviceList is the same
    return !demandResponseDeviceLists.every((subArr, _, arr) =>
      subArr.every((val, index) =>
        subArr.length === arr[0].length && val === arr[0][index]));
  }, [demandResponse]);

  const handleAddVehicleClick = () => {
    navigate("/addvehicle");
  };

  const handleRewardsInformationClick = () => {
    navigate("/rewards");
  };

  const handleAccountPageClick = () => {
    navigate("/account");
  };

  const handleOnRemove = (id: string) => {
    setDeleteId(id);

    // easy way to show modal is to just click a hidden button with data-te-target
    deleteModalButtonRef.current?.click();
  };

  const handleOnOverride = async (newManagedChargingEnabled: boolean, newOptOutEnabled: boolean) => {

    const optOutChanged = newOptOutEnabled !== optOutEnabled;
    const managedChargingChanged = newManagedChargingEnabled !== managedChargingEnabled;
    if (!optOutChanged && !managedChargingChanged) {
      return;
    }

    dispatch(updateManagedChargingEnabled(newManagedChargingEnabled));
    dispatch(updateOptOutEnabled(newOptOutEnabled));

    // set the same value for all cars
    if (firstManagableCar && user && cars) {
      trackEvent("ManagedChargingOverride", {
        "managedChargingChanged": managedChargingChanged,
        "optOutChanged": optOutChanged,
        "newOptOutEnabled": newOptOutEnabled,
        "newManagedChargingEnabled": newManagedChargingEnabled
      });

      for (const i in cars) {
        const car = cars[i];
        if (car.device.CanDeviceStartAndStopCharging) {
          // don't await so we  fire off all the requests at once
          if (managedChargingChanged) {
            SetDevicePreferences(user, car.device.GUID, {
              DeviceGUID: car.device.GUID,
              DisableSmartCharging: !newManagedChargingEnabled,
              DisableCarPolling: false
            });
          }
          if (optOutChanged) {
            SetOptInAfter(user, {
              DeviceGuid: car.device.GUID,
              TotalHours: newOptOutEnabled ? 12 : 0 // set hours to 0 to disable opt out. 
            });
          }
        }
      }
    }
  };

  const handleConfirmDelete = async () => { // TODO fix this
    dispatch(updateCarsLoading(true));
    deleteDevice(user!, deleteId)
      .then(() => {
        // this is the one situation where we need to load again on page
        okToLoadDeviceData.current = true;
        dispatch(updateCars(undefined));
      })
      .catch(error => {
        alert("There was an error removing the car.");
        dispatch(updateCarsLoading(false));
      });
  };

  const showDemandModal = () => {
    demandResponseModalButtonRef.current?.click();
  };
  /*
    useEffect(() => {
      if (teslaVirtualKeyPaired && teslaVirtualKeyPaired.length > 0) {
        virtualKeyModelButtonRef.current?.click();
      }
    }, [teslaVirtualKeyPaired]);
  */

  const handleChangeDemandReponseOptOut = async (eventId: string, optOut: boolean) => {
    if (user) {
      // TODO track this.

      // write it to the server
      await PutDemandResponseEventsOptOut(user, eventId, optOut);

      // update our local copy of the event
      dispatch(updateDemandResponseEventOptOut({ eventId, optOut }));
    }
  };

  if (cars && cars.length > 0 && cars.some((car) => car.isSimulated)) {
    // make fake demand response for simulated cars for demos if there isn't a real one
    if (!demandResponse || demandResponse.length === 0) {
      addFakeDemandResponseEvent(dispatch);
    }
  }

  return (
    <ResponsiveLayout>
      <div className="pb-[20px]">
        <div className={"my-[30px] flex justify-between"}>
          <BasicPageHeader title={config.dashboardHeadline} />
          <IconButton image={accountPageButton} onClick={handleAccountPageClick} altText={t("Account Information")} />
        </div>
        <div className={"dashboard-contents-container"}>
          {config.rewardsCards.length > 0 &&
            <div className={"flex justify-end border-t border-default"}>
              <div className={"flex flex-col justify-start my-[10px] relative"}>
                <button onClick={handleRewardsInformationClick} className={"absolute top-[3px] right-[10px] text-white text-sm font-sans-bold"}>{t("Program Rewards")}
                </button>
                <svg xmlns="http://www.w3.org/2000/svg" width="155" height="24" viewBox="0 0 155 24" fill="none">
                  <path d="M0.183594 24H155V0H0L12.0918 12.0918L0.183594 24Z" fill="#0FBAE0" />
                </svg>
              </div>
            </div>
          }
          {carsLoading && <Spinner />}
          {!carsLoading &&
            ((enableOverride && !carsLoading && firstManagableCar) ?
              <DashboardManageCharging managedChargingEnabled={managedChargingEnabled} optOutEnabled={optOutEnabled} onOverride={handleOnOverride} />
              : <div className="w-full border-b border-black"></div>)
          }
          {config.dashboardDemandResponse && demandResponse && demandResponse.length > 0 &&
            demandResponse.map((event) =>
              <DemandResponseEvent
                id={event.DemandResponseEvent.EventId}
                startDateUtc={event.DemandResponseEvent.StartTime}
                endDateUtc={event.DemandResponseEvent.EndTime}
                optOut={event.Members.some((member) => member.OptedOut)}
                showModal={showDemandModal}
                extraData={demandResponseEventsVaryByCar ? event.Members.map((member) => member.Device.FriendlyName).join(', ') : undefined}
                changeOptOut={handleChangeDemandReponseOptOut} />)
          }
          {teslaVirtualKeyPaired && teslaVirtualKeyPaired.some((t) => t.keyNeeded) &&
            <div className="pt-[10px]">
              <VirtualKeyInstructionsMessage />
            </div>
          }

          {loadError && <div className="w-full text-center p-[20px] font-sans-bold text-lg">{t("There was an internal error loading your data. Please try again later.")}</div>}

          < div className={"pt-[10px]"}>
            {!carsLoading && cars && cars.map((car) => {
              return (
                <DashboardDevice
                  id={car.device.GUID}
                  key={`device-${car.device.GUID}`}
                  logo={getLogo(car.device.Make)}
                  model={car.device.CarType}
                  friendlyName={car.device.FriendlyName}
                  vin={car.device.UniqueId}
                  dailyChargeEnergySummary={car.dailyChargeEnergySummary}
                  monthlyChargeEnergySummary={car.monthlyChargeEnergySummary}
                  status={car.statusString}
                  lastSeen={car.state?.LastSeenTime ? new Date(car.state.LastSeenTime) : undefined}
                  onRemove={handleOnRemove}
                />
              );
            })}
          </div>
          {!carsLoading && !loadError &&
            <div className={"mt-[20px] flex justify-end"}>
              <PillButton text={t('Add Vehicle')} icon={"+"} onClick={handleAddVehicleClick} />
            </div>
          }
        </div>

        <Modal id="confirm-delete-modal" label="Remove Vehicle" openButtonRef={deleteModalButtonRef} cancelButton>
          <div className={"flex flex-col items-center text-center"}>
            <div className={"mb-[15px] mt-[20px]"}><img src={warningIcon} alt="warning" /></div>
            <div className={"text-lg"}>{t("You are deleting all information for this vehicle from your account.")}</div>
            <div className={"text-lg font-sans-bold mt-[40px]"}>{t("This cannot be undone.")}</div>
            <div className={"flex justify-between mt-[30px] mb-[20px] w-[135px]"} data-te-modal-dismiss>
              <Button text={t("Delete")} variant="primary" pill fullWidth onClick={handleConfirmDelete} />
            </div>
          </div>
        </Modal>

        <DemandResponseInfoModal openButtonRef={demandResponseModalButtonRef} />

        <VirtualKeyInstructionsModal openButtonRef={virtualKeyModelButtonRef} />
      </div>
    </ResponsiveLayout >
  );
};

