import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import { MulticolorHeadline } from "../components/Shared/MulticolorHeadline";
import { Button } from "../components/Shared/Button";
import { useSignUpStep } from "../hooks/useSignUpStep/useSignUpStep";
import useBrandConfig from "../hooks/useBrandConfig";
import { getImageURL } from "../brand-config";
import { useTrackEvent } from "../hooks/useTrackEvent/useTrackEvent";
import { RewardsProgramInformationCard } from "../components/RewardsProgramInformationCard/RewardsProgramInformationCard";
import { t } from "i18next";

const WelcomeImage = ({ brand }: { brand: string }) => {
  // image that shrinks when the height gets too low.
  return (
    <img
      className={"max-w-[308px]"}
      alt={"Vehicle Added"}
      onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => event.currentTarget.style.display = 'none'}
      src={getImageURL("rewardsSuccess", brand)}
    />
  );
};

export const Welcome = () => {
  const trackEvent = useTrackEvent();

  const navigate = useNavigate();

  const [isNavigating] = useState(false);
  const [, setSignUpStep] = useSignUpStep();
  const config = useBrandConfig();

  const handleContinueClick = () => {
    setSignUpStep(null);
    trackEvent("SignUpCompleted");
    navigate("/dashboard");
  };

  return (
    <ResponsiveLayout>
      <>
        <MulticolorHeadline
          firstSentence={t("Welcome")}
          secondSentence={t("to your program!")}
          breakBetween />
        <div className={"flex justify-center mt-[10px] md:mt-[20px]"}>
          <WelcomeImage brand={config.programName} />
        </div>

        <div className={"mt-[20px] md:mt-[30px] mb-[200px]"}>
          {[0, 1, 2, 3].map((row) => {
            return (
              <div key={row} className={"lg:flex lg:gap-3"}>
                {config.rewardsCards.filter((card) => card.row === row).map((card, index) => {
                  return (
                    <RewardsProgramInformationCard
                      key={index}
                      header={card.title}
                      dollars={card.amount}
                      body={card.text}
                      footer={card.subtext}
                    />
                  )
                })}
              </div>)
          })}
        </div>

        <div className={"mt-[42px] pb-[20px] flex justify-center bottom-pinned-button"}>
          <Button
            variant="primary"
            shadow
            text={isNavigating ? t("Loading...") : config.welcomeButtonText}
            busy={isNavigating}
            onClick={handleContinueClick} />
        </div>
      </>
    </ResponsiveLayout>
  );
};
