import { useNavigate } from "react-router-dom";
import { Button } from "../components/Shared/Button";
import { MulticolorHeadline } from "../components/Shared/MulticolorHeadline";
import { ResponsiveLayout } from "../components/Shared/ResponsiveLayout";
import useBrandConfig from "../hooks/useBrandConfig";
import { t } from "i18next";
import { QueryString } from "../constants/queryString";

export const AccountCreated = () => {
  const navigate = useNavigate();
  const config = useBrandConfig();

  const handleContinueClick = () => {
    // don't set the sign up step to addvehicle to simplify the logic in the UnauthenticatedLayout.
    // we'll just return here if someone happens to abort from addvehicle, no big deal.
    navigate(`/addvehicle?${QueryString.SignUp}`);
  };

  return (
    <ResponsiveLayout>
      <>
        <MulticolorHeadline
          firstSentence={config.accountCreatedHeadlineOne}
          secondSentence={config.accountCreatedHeadlineTwo}
        />
        <div className={"flex flex-col md:items-center text-2xl md:text-4xl mt-[60px] md:mt-[85px]"}>
          <h3 className={"font-sans-bold"}>1&nbsp;&nbsp;{config.signUpStepOne}</h3>
          <h3 className={"opacity-50 mix-blend-normal pt-[14px] md:pt-[40px]"}>2&nbsp;&nbsp;{config.signUpStepTwo}</h3>
        </div>

        <div className={"mt-[120px] pb-[20px] flex justify-center bottom-pinned-button"}>
          <Button
            variant="primary"
            text={t('Connect My EV')}
            onClick={handleContinueClick}
          />
        </div>
      </>
    </ResponsiveLayout>
  );
};
