import React, { useEffect, useState } from "react";
import { Header } from "../components/Header/Header";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSignUpStep } from "../hooks/useSignUpStep/useSignUpStep";
import { useAuth } from "../hooks/useAuth/useAuth";
import { QueryString } from "../constants/queryString";
import useBrandConfig from "../hooks/useBrandConfig";

// This layout alwayss redirects to the dashboard if the user is authenticated (or the curren sign up step)
export const UnauthenticatedLayout = ({
  children,
  hasHeader = true
}: {
  children: JSX.Element;
  hasHeader?: boolean;
}) => {
  const { user, hasAccount, isImpersonating } = useAuth();
  const navigate = useNavigate();
  const [signUpStep] = useSignUpStep();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [redirectsHandled, setRedirectsHandled] = useState(false);
  const config = useBrandConfig();

  // resume where the user left off in the sign up process
  useEffect(() => {
    setRedirectsHandled(true);

    if (config.hasLandingPage && searchParams.has(QueryString.ShowLandingPage) && location.pathname === "/") {
      return;
    }

    if (user) {
      if (signUpStep && !isImpersonating) {
        navigate(`/${signUpStep}`);
      } else {
        // terms and conditions - switch from unauthenticated to authenticated version of this page
        if (location.pathname === "/termsandconditions") {
          navigate("/account/termsandconditions");
          return;
        }

        // get the returnUrl parameter value out of the search string 
        const returnUrl = searchParams.get(QueryString.ReturnUrl);

        // go to dashboard unless there is a return url
        navigate(returnUrl || "/dashboard", { replace: true });
      }
    }
    else {
      // unauthenticated root goes to either login, signup or landing page
      if (location.pathname === "/") {
        if (hasAccount) {
          navigate("/login")
        }
        else if (!config.hasLandingPage) {
          // if there is no landing page, go straight to signup
          navigate("/signup");
        }
        // otherwise go to the page for the path in app.tsx        
      }
    }
  }, [user, signUpStep, navigate, location.search, location.pathname, searchParams, config.hasLandingPage, hasAccount, isImpersonating]);

  // if we haven't handled any redirects don't render anything (to avoid flicker)
  if (!redirectsHandled) {
    return null;
  }

  return (
    <>
      {hasHeader && <Header />}
      {children}
    </>
  );
};
